import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { StyleSheet, css } from 'aphrodite/no-important';

const Home = (props) => {
  const {
    pageContext: { year },
  } = props;

  return (
    <Layout year={year}>
      <div className={css(styles.content)}>
        <div className={css(styles.row)}>Page not found</div>
        <div className={css(styles.row)}>
          <Link to="/">Return home</Link>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

const styles = StyleSheet.create({
  content: {
    textAlign: 'center',
  },
  row: {
    margin: '10px auto',
  },
});
